<template>
<div class="product-list">
  <van-nav-bar :title="typeName?typeName:'投放景区类型'" @click-left="onClickLeft">
    <template #left>
      <van-image id="listBack" :src="require('@/assets/images/back.png')" />
    </template>
  </van-nav-bar>
  <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
    <van-grid id="productBox" :column-num="2" :gutter="8">
      <van-grid-item class="product-item" v-for="(item,index) in productList" :key="'pd'+index" :data-id="item.id" @click="toDetail(item.id)">
        <van-image :src="item.pic" lazy-load :rel="item.name"/>
        <div class="info-box">
          <div class="name">{{item.name}}</div>
          <div class="intro">{{item.describe?item.describe:item.intro}}</div>
          <div class="price">￥<span>{{item.price?item.price:0}}</span></div>
        </div>
      </van-grid-item>
    </van-grid>
  </van-list>
</div>
</template>

<script>
import { Toast } from 'vant'
import { PAGE_START, PAGE_SIZE, RES_NORMAL_SUCCESS_CODE, CX_PID_KEY } from '@/utils/constants'
import { getImgArr } from '@/utils/function'
export default {
  name: 'List',
  data () {
    return {
      pagination: {pageNum: PAGE_START, pageSize: PAGE_SIZE},
      productList: [],
      loading: false,
      finished: false,
      typeId: '',
      typeName: ''
    }
  },
  created() {
    const type = this.$route.query.typeId
    const typeName = this.$route.query.typeName
    if (type) {
      this.typeId = type
    } else {
      Toast.fail({message: '投放景区类型id未传', forbidClick: true})
    }
    if (typeName) {
      this.typeName = typeName
    } else {
      Toast.fail({message: '投放景区类型名称未传', forbidClick: true})
    }
  },
  methods: {
    getPdList () {
      const params = {pageNum: this.pagination.pageNum, pageSize: this.pagination.pageSize}
      if (this.typeId) { params.productType = this.typeId }
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getProduct', params).then(res => {
        this.loading = false
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          this.productList = res.data.data.rows
          this.productList.forEach(item => {
            if (item.masterImg) {
              const imgArr = getImgArr(item.masterImg)
              item.pic = imgArr[0].url
            } else { item.pic = this.redUrl }
          })
          this.pagination.pageNum++
          if ((res.data.data.total === 0) || ((this.pagination.pageNum-1) === Math.ceil(res.data.data.total / this.pagination.pageSize))) {
            this.finished = true
          }
        } else {
          Toast.fail({ message: '获取产品列表失败', forbidClick: true });
          console.log(res.data)
        }
      }).catch(err => {
        this.loading = false
        Toast.fail({ message: '获取产品列表失败', forbidClick: true });
        console.error(err)
      })
    },
    toDetail (id) {
      this.$db.save(CX_PID_KEY, id)
      this.$router.push({ path: '/detail', query: {id: id}})
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    onLoad () {
      this.getPdList()
    }
  }
}
</script>

<style lang="less">
#listBack {
  .van-image__img {
    width: 30px;
    height: 30px;
  }
}
.product-item {
  .van-grid-item__content--center {
    justify-content: flex-end;
    border-radius: 12px;
  }
  .van-image__img {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .van-grid-item__content {
    padding: 0;
  }
  .van-grid-item__content::after {
    border: none;
  }
}
</style>
<style lang="less" scoped>
.product-list {
  background-color: #f4f8fb;
  header {
    padding: 0 8px;
    background-color: #FFFFFF;
  }
  #productBox {
    .product-item {
      margin-bottom: 8px;
      &:nth-last-child(1),&:nth-last-child(2){
        margin: 0;
      }
      overflow: hidden;
      .info-box {
        box-sizing: border-box;
        width: 100%;
        padding: 8px;
        text-align: left;
        background-color: #FFFFFF;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;
        .name {
          color: #515151;
          font-size: 10.5pt;
        }
        .intro {
          margin: 8px 0;
          color: #AEAEAE;
          font-size: 8.25pt;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap
        }
        .price {
          color: #F25822;
          font-size: 7.5pt;
          span {
            font-size: 12pt;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>