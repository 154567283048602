<template>
  <div class="container">
    <header>
      <van-row justify="center" align="center">
        <van-col span="5"><van-image id="logo" lazy-load :src="require('@/assets/images/logo.png')" /></van-col>
        <van-col span="17"><van-search v-model="word" shape="round" placeholder="景区观光车" left-icon="" right-icon="search" @search="reset"/></van-col>
        <van-col span="2"><van-image id="share" lazy-load :src="require('@/assets/images/share.png')" @click="toShare"/></van-col>
      </van-row>
    </header>
    <van-image id="big" lazy-load :src="require('@/assets/images/big.png')" />
    <van-grid :column-num="4" id="typeBox">
      <van-grid-item v-for="(item,index) in gridArr" :key="'grid'+index" class="type-item" @click="changeType(item.id, item.name)">
        <van-image :src="item.iconUrl" lazy-load :rel="item.name"/>
        <span>{{item.name}}</span>
      </van-grid-item>
    </van-grid>
    <div id="serviceBox">
      <div class="service-item" @click="toAd('d79e3ff441214c8ea5b98398bbf31e84','观光车改造')">
        <van-image lazy-load :src="require('@/assets/images/ad_car.png')" />
      </div>
      <div class="service-item" @click="toAd('f6849fed2d14488d95669f19113f2e11','共享车升级')">
        <van-image lazy-load :src="require('@/assets/images/ad_bike.png')" />
      </div>
    </div>
    <ul id="scenicType">
      <li v-for="(item,index) in scenicArr" :key="'scenic'+index" :data-scenic="item.id" @click="changeScenic(item.id)">
        <span :class="activeScenic === item.id?'checked':''">{{item.name}}</span>
      </li>
    </ul>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-grid id="productBox" :column-num="2" :gutter="8">
        <van-grid-item class="product-item" v-for="(item,index) in productArr" :key="'pd'+index" :data-id="item.id" @click="toDetail(item.id)">
          <van-image :src="item.pic" lazy-load :rel="item.name"/>
          <div class="info-box">
            <div class="name">{{item.name}}</div>
            <div class="intro">{{item.describe?item.describe:item.intro}}</div>
            <div class="price">￥<span>{{item.price?item.price:0}}</span></div>
          </div>
        </van-grid-item>
      </van-grid>
    </van-list>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { PAGE_START, PAGE_SIZE, RES_NORMAL_SUCCESS_CODE, CX_PID_KEY } from '@/utils/constants'
import { getImgArr } from '@/utils/function'
import wx from 'weixin-js-sdk'
export default {
  name: 'Index',
  data () {
    return {
      activeScenic: 1,
      redUrl: require('@/assets/images/smart_red.png'),
      word: '',
      pagination: {pageNum: PAGE_START, pageSize: PAGE_SIZE},
      gridArr: [],
      scenicArr: [],
      productArr: [],
      loading: false,
      finished: false,
      isWeiXin: false
    }
  },
  created() {
    this.isWeiXin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    this.getType()
    this.getPdScenicType()
    if (this.isWeiXin) {
      this.getWxConfig()
      wx.ready(function(){
        console.log('wx config success:');
        wx.onMenuShareAppMessage({
          title: '畅行共享商城',
          desc: '智能出行装备一站式直供平台，汇聚全球精选好货，极速开启无人运营。',
          link: window.location.href,
          imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
          type: 'link',
          dataUrl: '',
          success: function () {}
        });
        wx.onMenuShareTimeline({
          title: '畅行共享商城',
          link: window.location.href,
          imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
          success: function () {}
        });
      });
      wx.error(function(res){
        console.error('wx fail:');
        console.error(res);
      });
    }
  },
  methods: {
    reset () {
      this.pagination = {pageNum: PAGE_START, pageSize: PAGE_SIZE}
      this.productArr = []
      this.finished = false
    },
    getType () {
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getProductType').then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          this.gridArr = res.data.data
          this.gridArr.forEach(item => {
            item.iconUrl = require(`@/assets/images/type_${item.id}.png`)
          })
        } else {
          Toast.fail({ message: '获取产品类型失败', forbidClick: true });
          console.log(res.data)
        }
      }).catch(err => {
        Toast.fail({ message: '获取产品类型失败', forbidClick: true });
        console.error(err)
      })
    },
    getPdScenicType () {
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getScenicType').then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          this.scenicArr = res.data.data
        } else {
          Toast.fail({ message: '获取产品投放景区类型失败', forbidClick: true });
          console.log(res.data)
        }
      }).catch(err => {
        Toast.fail({ message: '获取产品投放景区类型失败', forbidClick: true });
        console.error(err)
      })
    },
    onLoad () {
      this.getPdList()
    },
    getPdList () {
      const params = {pageNum: this.pagination.pageNum, pageSize: this.pagination.pageSize}
      if (this.activeScenic) { params.productScenicType = this.activeScenic }
      if (this.word) { params.name = this.word }
      this.$get('/mfr/getProduct', params).then(res => {
        this.loading = false
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          this.productArr = res.data.data.rows
          this.productArr.forEach(item => {
            if (item.masterImg) {
              const imgArr = getImgArr(item.masterImg)
              item.pic = imgArr[0].url
            } else { item.pic = this.redUrl }
          })
          this.pagination.pageNum++
          if ((res.data.data.total === 0) || ((this.pagination.pageNum-1) === Math.ceil(res.data.data.total / this.pagination.pageSize))) {
            this.finished = true
          }
        } else {
          console.log('获取产品列表失败')
          console.log(res.data)
        }
      }).catch(err => {
        this.loading = false
        console.error('获取产品列表失败')
        console.error(err)
      })
    },
    changeType (id, name) {
      this.$router.push({ path: '/list', query: { typeId: id, typeName: name } })
    },
    changeScenic (id) {
      if (this.activeScenic === id) { return }
      this.activeScenic = id
      this.reset()
    },
    toDetail (id) {
      this.$db.save(CX_PID_KEY, id)
      this.$router.push({ path: '/detail', query: {id: id}})
    },
    toShare () {
      if (!this.isWeiXin) { Toast.fail({ message: '请在微信浏览器中打开', forbidClick: true }); return }
      Toast.fail({ message: '请点击右上角分享', forbidClick: true });
    },
    toAd (id, name) {
      this.$db.save(CX_PID_KEY, id)
      this.$router.push({ path: '/advertising', query: {id: id, name: name}})
    },
    getWxConfig() {
      const url = window.location.href.split('#')[0];
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getWxConfig', {url: encodeURIComponent(url)}).then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          const result = res.data.data;
          console.log('get wx config:');
          console.log(result);
          if (Object.keys(result).length > 0) {
            wx.config({
              debug: false,
              appId: result.appId,
              timestamp: result.timestamp,
              nonceStr: result.nonceStr,
              signature: result.signature,
              jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage']
            });
          } else {
            Toast.fail({ message: '配置信息获取失败', forbidClick: true });
            console.error('配置信息获取失败');
            console.error(result);
          }
        } else {
          Toast.fail({ message: '配置信息获取失败', forbidClick: true });
          console.error('配置信息获取失败');
          console.error(res.data);
        }
      }).catch(err => {
        Toast.fail({ message: '配置信息获取失败', forbidClick: true });
        console.error('配置信息获取失败');
        console.error(err);
      });
    }
    // ,
    // getCode(code) {
    //   Toast.loading({ message: '加载中...', forbidClick: true });
    //   this.$get(baseUrl+'/loginRedirect', {code: code}).then(res => {
    //     if(res.data.code === RES_NORMAL_SUCCESS_CODE) {
    //       const result = res.data.data;
    //       localStorage.setItem(TOKEN_KEY, result);
    //       this.getWxConfig()
    //     } else {
    //       Toast.fail({ message: '获取token失败', forbidClick: true });
    //       console.error('get token fail:');
    //       console.error(res.data);
    //     }
    //   }).catch(err => {
    //     Toast.fail({ message: '获取token失败', forbidClick: true });
    //     console.error('get token fail:');
    //     console.error(err);
    //   })
    // }
  }
}
</script>

<style lang="less">
.van-hairline--top::after {
  border: none;
}
#big.van-image {
  display: block;
  font-size: 0;
}
.type-item {
  .checked {
    color: #17A084;
  }
  .van-image {
    font-size: 0;
  }
  .van-image__img {
    width: 50px;
    height: 50px;
  }
  .van-grid-item__content {
    padding: 0 8px;
  }
  .van-grid-item__content::after {
    border: none;
  }
}
.service-item {
  .van-image {
    display: block;
    font-size: 0;
  }
  .van-image__img {
    width: 100%;
    height: auto;
    display: block;
    font-size: 0;
  }
}
.product-item {
  .van-grid-item__content--center {
    justify-content: flex-end;
    border-radius: 12px;
  }
  .van-image {
    display: block;
    font-size: 0;
  }
  .van-image__img {
    display: block;
    font-size: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .van-grid-item__content {
    padding: 0;
  }
  .van-grid-item__content::after {
    border: none;
  }
}
</style>
<style lang="less" scoped>
.container {
  background-color: #F4F8FB;
  header {
    padding: 0 8px;
    background-color: #FFFFFF;
  }
  #typeBox {
    padding: 0 0 25px 0;
    background-color: #FFFFFF;
  }
  #logo {
    width: 71px;
    height: 27px;
    margin: 11px 0;
  }
  #share {
    width: 22px;
    height: 22px;
    margin: 14px 0;
  }
  #big {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 0 8px;
    background-color: #FFFFFF;
  }
  .type-item {
    margin-top: 20px;
    span {
      font-size: 8.25pt;
      margin-top: 6px;
      color: #333333;
    }
  }
  #serviceBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #FFFFFF;
    padding: 0 8px;
    .service-item {
      flex: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
  #scenicType {
    white-space: nowrap;
    overflow-x: scroll;
    padding: 8px;
    background-color: #FFFFFF;
    list-style-type: none;
    text-align: left;
    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 7px;
      font-size: 10.5pt;
      span {
        display: inline-block;
        padding: 8px 0;
      }
      .checked {
        color: #17A084;
        border-bottom: 2px solid #17A084;
      }
    }
  }
  #productBox {
    .product-item {
      overflow: hidden;
      .info-box {
        box-sizing: border-box;
        width: 100%;
        padding: 8px;
        text-align: left;
        background-color: #FFFFFF;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        overflow: hidden;
        .name {
          color: #515151;
          font-size: 10.5pt;
        }
        .intro {
          margin: 8px 0;
          color: #AEAEAE;
          font-size: 8.25pt;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap
        }
        .price {
          color: #F25822;
          font-size: 7.5pt;
          span {
            font-size: 12pt;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>