export const PAGE_START = 1
export const PAGE_SIZE = 10

export const RES_NORMAL_SUCCESS_CODE = 1
export const RES_LOGIN_INVALID_CODE = -1
export const RES_NORMAL_FAIL_CODE = 0

export const IMPORT_IMAGE_HOST = 'https://images.evhack.work'
export const INTRANET_IMAGE_HOST = 'https://evhack.oss-cn-hangzhou-internal.aliyuncs.com'// 内网
export const IMAGE_HOST = 'https://evhack.oss-cn-hangzhou.aliyuncs.com'// 外网
export const THUMBNAIL_IMAGE_SUFFIX = '?x-oss-process=style/mystyle'
export const ORIGINAL_IMAGE_SUFFIX = '?x-oss-process=style/orisytle'
export const OTHER_IMAGE_SUFFIX = '?x-oss-process=style/ori'

export const WebServiceKey = 'f651d65ac02e0e043ead1bf2f38d1137'
export const Coordinate_Convert = 'https://restapi.amap.com/v3/assistant/coordinate/convert'

export const CX_PID_KEY = 'CX_PRODUCT_ID'
export const ACCESS_TOKEN_KEY = 'ss_a_t'
export const FRESH_TOKEN_KEY = 'ss_f_t'
export const WX_CODE_KEY = 'ss_w_c'
export const TOKEN_KEY = 'ss_t_k'
export const baseUrl = 'https://zero.jqy.evhack.work/cxj/wxWeb'

export const SIGHTSEEING_AD_URL = 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad1126/upcar/'
export const SHARE_CAR_AD_URL = 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad1126/upbike/'