<template>
<div class="ad-box">
  <van-nav-bar :title="name?name:'广告名称'" @click-left="onClickLeft" @click-right="toShare">
    <template #left>
      <van-image id="adBack" lazy-load :src="require('@/assets/images/back.png')"/>
    </template>
    <template #right>
      <van-image id="adShare" lazy-load :src="require('@/assets/images/share_round.png')"/>
    </template>
  </van-nav-bar>
  <section id="adContent">
    <van-image v-for="(item, index) in imgArr" :key="'ad'+index" lazy-load :src="item" />
  </section>
  <van-goods-action id="adAction">
    <a href="tel:400-112-8680"><van-goods-action-icon icon="service-o" text="客服"/></a>
    <van-goods-action-button color="#17A084" text="获取项目详情" @click="toGetProduct" />
  </van-goods-action>
</div>
</template>

<script>
import wx from 'weixin-js-sdk';
import {Toast} from 'vant';
import {CX_PID_KEY, RES_NORMAL_SUCCESS_CODE, SHARE_CAR_AD_URL, SIGHTSEEING_AD_URL, OTHER_IMAGE_SUFFIX} from '@/utils/constants';
export default {
  name: 'Advertising',
  data () {
    return {
      adName: '',
      imgArr: [],
      id: '',
      name: '',
      intro: ''
    }
  },
  created() {
    const id = this.$route.query.id
    const name = this.$route.query.name
    if (id) { this.id = id } else {
      let localId = this.$db.get(CX_PID_KEY, '')
      if (localId) { this.id = localId } else {
        Toast.fail({ message: '广告id未传', forbidClick: true });
      }
    }
    if (name) { this.name = this.$route.query.name } else {
      Toast.fail({ message: '广告名称未传', forbidClick: true });
    }
    if (this.id === 'd79e3ff441214c8ea5b98398bbf31e84') {
      this.intro = '一键升级您的观光车'
      for(let i = 1; i <= 9; i++) {
        this.imgArr.push(`${SIGHTSEEING_AD_URL}${i}.jpg${OTHER_IMAGE_SUFFIX}`)
      }
    } else if (this.id === 'f6849fed2d14488d95669f19113f2e11') {
      this.intro = '一键升级您的共享自行车'
      for(let i = 1; i <= 14; i++) {
        this.imgArr.push(`${SHARE_CAR_AD_URL}${i}.jpg${OTHER_IMAGE_SUFFIX}`)
      }
    }
    this.isWeiXin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    this.shareFun()
  },
  methods: {
    onClickLeft () {
      if (window.history.length <= 1) {
        this.$router.replace({path: '/'})
      } else { this.$router.go(-1) }
    },
    toShare () {
      if (!this.isWeiXin) { Toast.fail({ message: '请在微信浏览器中打开', forbidClick: true }); return }
      Toast.fail({ message: '请点击右上角分享', forbidClick: true });
    },
    toGetProduct () {
      this.$router.push({ path: '/form', query: { productId: this.id, productName: this.name } })
    },
    shareFun () {
      if (!this.isWeiXin) { return }
      const that = this
      this.getWxConfig()
      wx.ready(function(){
        console.log('wx config success:');
        wx.onMenuShareAppMessage({
          title: ''+that.name,
          desc: ''+that.intro,
          link: window.location.href,
          imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
          type: 'link',
          dataUrl: '',
          success: function () {}
        });
        wx.onMenuShareTimeline({
          title: ''+that.name,
          link: window.location.href,
          imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
          success: function () {}
        });
      });
      wx.error(function(res){
        console.error('wx fail:');
        console.error(res);
      });
    },
    getWxConfig() {
      const url = window.location.href.split('#')[0];
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getWxConfig', {url: encodeURIComponent(url)}).then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          const result = res.data.data;
          console.log('get wx config:');
          console.log(result);
          if (Object.keys(result).length > 0) {
            wx.config({
              debug: false,
              appId: result.appId,
              timestamp: result.timestamp,
              nonceStr: result.nonceStr,
              signature: result.signature,
              jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage']
            });
          } else {
            Toast.fail({ message: '配置信息获取失败', forbidClick: true });
            console.error('配置信息获取失败');
            console.error(result);
          }
        } else {
          Toast.fail({ message: '配置信息获取失败', forbidClick: true });
          console.error('配置信息获取失败');
          console.error(res.data);
        }
      }).catch(err => {
        Toast.fail({ message: '配置信息获取失败', forbidClick: true });
        console.error('配置信息获取失败');
        console.error(err);
      });
    }
  }
}
</script>

<style lang="less">
#adContent {
  .van-image {
    display: block;
    font-size: 0;
  }
  .van-image__img {
    width: 100%;
    height: auto;
    border-radius: 0;
    display: block;
    font-size: 0;
  }
}
</style>
<style lang="less" scoped>
.ad-box {
  #adShare,#adBack {
    width: 30px;
    height: 30px;
  }
  #adContent {
    padding-bottom: 50px;
    background-color: #FFFFFF;
    img {
      width: 100%;
      height: auto;
    }
  }
  #adAction {
    height: 60px;
  }
}
</style>