<template>
<div class="form-box">
  <h1>现在申请</h1>
  <van-form id="form" @submit="onSubmit">
    <div id="cellBox">
      <van-field
          v-model="scenicName"
          name="景区名称"
          label="景区名称："
          placeholder="请输入景区名"
          input-align="right"
          :rules="[{ required: true, message: '请输入景区名' }]"
      />
      <van-field
          v-model="realName"
          name="联系人"
          label="联系人："
          placeholder="请输入联系人"
          input-align="right"
          :rules="[{ required: true, message: '请输入联系人' }]"
      />
      <van-field
          v-model="phone"
          name="联系电话"
          label="联系电话："
          placeholder="请输入联系电话"
          input-align="right"
          :rules="[{ required: true, message: '请输入联系电话' },{ validator: checkPhone, message: '请输入有效的联系电话' }]"
      />
    </div>
    <van-button id="submit" color="#17A084" round native-type="submit">获取项目详情</van-button>
  </van-form>
</div>
</template>

<script>
import { Toast } from 'vant'
import {RES_NORMAL_SUCCESS_CODE} from '@/utils/constants'
export default {
  name: 'Form',
  data () {
    return {
      scenicName: '',
      realName: '',
      phone: '',
      productId: '',
      productName: ''
    }
  },
  created() {
    const id = this.$route.query.productId
    const name = this.$route.query.productName
    if (!id) { Toast.fail({ message: '商品id未传', forbidClick: true }) } else { this.productId = id }
    if (!name) { Toast.fail({ message: '商品名称未传', forbidClick: true }) } else { this.productName = name }
  },
  methods: {
    reset () {
      this.scenicName = ''
      this.realName = ''
      this.phone = ''
    },
    onSubmit () {
      const that = this
      if (!this.productId || !this.productName) { return }
      Toast.loading({ message: '加载中...', forbidClick: true });
      const params = { fromWay: 5, fromId: this.productId, phone: this.phone, username: this.realName, company: this.scenicName }
      this.$post('/insert',params).then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          console.log(res.data)
          Toast.success({ message: '提交成功', forbidClick: true, onClose() { that.$router.replace({ path: '/' }) } });
        } else {
          Toast.fail({ message: '提交失败', forbidClick: true });
          console.log(res.data)
        }
      }).catch(err => {
        Toast.fail({ message: '提交失败', forbidClick: true });
        console.error(err)
      })
    },
    checkPhone() {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      // let cm = /^(1(34[0-8]|(3[5-9]|47|48|5[0-27-9]|72|78|8[2-478]|98)\d|70[356]|1440)\d{7})$/;
      // let cu = /^(1(70[47-9]|(3[0-2]|4[56]|5[56]|66|7[156]|8[56])\d)\d{7})$/;
      // let ct = /^(1((33|49|53|7[37]|8[019]|199)\d|70[0-2]|1410)\d{7})$/;
      // let other = /^((1349\d|1740[05]|1749\d)\d{6})/;
      if (!this.phone || this.phone === 'undefined') {
        return false;
      } else if (reg.test(this.phone)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.form-box {
  background-color: #F4F8FB;
  h1 {
    text-align: left;
    font-size: 12.75pt;
    padding: 16px 16px 16px 24px;
    margin: 0;
  }
  #cellBox {
    margin: 0 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
  }
  #submit {
    width: calc(100vw - 20px);
    height: 47px;
    margin: 16px;
    font-size: 12.75pt;
  }
}
</style>