import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import Vant from 'vant'
import store from './store'
import request from './utils/request'
import db from './utils/localstorage'
import { Col, Row, Image as VanImage, Lazyload, Search, Toast , Dialog, Notify, Button, Field, Form, List,
  Grid, GridItem, Swipe, SwipeItem, GoodsAction, GoodsActionIcon, GoodsActionButton, NavBar } from 'vant';

// import 'vant/lib/index.css'

Vue.config.productionTip = false
// Vue.use(Vant)
Vue.use(db)
Vue.use(Col)
Vue.use(Row)
Vue.use(VanImage)
Vue.use(Lazyload)
Vue.use(Search)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Button)
Vue.use(Form)
Vue.use(List)
Vue.use(Field)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(GoodsAction)
Vue.use(GoodsActionIcon)
Vue.use(GoodsActionButton)
Vue.use(NavBar)

Vue.use({
  install (Vue) {
    Vue.prototype.$db = db
  }
})

Vue.prototype.$post = request.post
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$delete = request.delete
Vue.prototype.$export = request.export
Vue.prototype.$download = request.download
Vue.prototype.$upload = request.upload
Vue.prototype.$putForm = request.putForm


Vue.prototype.$EventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
