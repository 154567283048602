import { IMAGE_HOST, OTHER_IMAGE_SUFFIX, ORIGINAL_IMAGE_SUFFIX } from './constants'
const getImgArr = function (imgurl) {
  let arr = []
  let filterArr = []
  let imgArr = []
  let url = ''
  let temp = ''
  let oriUrl = ''
  let width = 0
  let height = 0
  let realHeight = 0
  if (imgurl && imgurl.includes(',')) {
    arr = imgurl.split(',')
  } else if (imgurl) {
    arr = [imgurl]
  }
  filterArr = arr.filter(function (item) {
    if (item) return item
  })
  if (filterArr.length > 0) {
    for (let i = 0; i < filterArr.length; i++) {
      temp = filterArr[i].split('-')[0]
      const originUrl = filterArr[i]
      let numstr = filterArr[i].split('-')[1]
      width = numstr.split('*')[0]
      height = numstr.split('*')[1]
      realHeight = width * 48 / height
      url = IMAGE_HOST + temp + ORIGINAL_IMAGE_SUFFIX
      oriUrl = IMAGE_HOST + temp + OTHER_IMAGE_SUFFIX
      var obj = {}
      obj.url = url
      obj.oriUrl = oriUrl
      obj.originUrl = originUrl
      obj.height = realHeight
      imgArr.push(obj)
    }
  }
  return imgArr
}

function withValue(value) {
  const d = withValue.d || (
    withValue.d = {
      enumerable: true,
      writable: false,
      configurable: true,
      value: null
    }
  );
  d.value = value;
  return d;
}

function accSub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

export { getImgArr, withValue, accSub }
