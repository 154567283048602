<template>
<div class="product-detail">
  <nav id="nav">
    <van-image class="back" lazy-load :src="require('@/assets/images/back.png')" @click="goBack"/>
    <van-image class="share" lazy-load :src="require('@/assets/images/share_round.png')" @click="toShare" />
  </nav>
  <van-swipe id="swipeBox" :autoplay="3000">
    <van-swipe-item v-for="(item, index) in imgArr" :key="'pdp'+index">
      <van-image lazy-load :src="item.oriUrl?item.oriUrl:redUrl" />
    </van-swipe-item>
  </van-swipe>
  <section id="productInfo">
    <div class="price">￥<span>{{product.price?product.price:0}}</span></div>
    <h2>{{product.name}}</h2>
    <p>{{product.intro}}</p>
  </section>
  <ul id="tagBox">
    <li v-for="(item, index) in tagArr" :key="'tag'+index"><van-image lazy-load :src="tagUrl" /><span>{{item}}</span></li>
  </ul>
  <section id="productContent">
    <div class="tooltip">产品详情：</div>
    <div v-html="product.content"></div>
  </section>
  <van-goods-action id="goodsAction">
    <a href="tel:400-112-8680"><van-goods-action-icon icon="service-o" text="客服"/></a>
    <van-goods-action-button color="#17A084" text="获取项目详情" @click="toGetProduct" />
  </van-goods-action>
</div>
</template>

<script>
import {CX_PID_KEY, RES_NORMAL_SUCCESS_CODE} from '@/utils/constants'
import { Toast } from 'vant'
import { getImgArr } from '@/utils/function'
import wx from 'weixin-js-sdk'
export default {
  name: 'Detail',
  data () {
    return {
      redUrl: require('@/assets/images/smart_red.png'),
      tagUrl: require('@/assets/images/tag_icon.png'),
      id: '',
      product: {},
      imgArr: [],
      tagArr: [],
      isWeiXin: false
    }
  },
  created() {
    const id = this.$route.query.id
    if (id) { this.id = id } else {
      let localId = this.$db.get(CX_PID_KEY, '')
      if (localId) { this.id = localId }
    }
    this.isWeiXin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    this.getDetail()
  },
  methods: {
    getDetail () {
      if (!this.id) { Toast.fail({ message: '商品id未传', forbidClick: true}); return }
      const that = this
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getProductDetail',{id: this.id}).then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          this.product = res.data.data
          if (this.product.masterImg) {
            this.imgArr = getImgArr(this.product.masterImg)
          }
          if (this.product.content) {
            this.product.content = this.product.content.replace(/<img/g,'<img style="max-width:100% !important;height:auto !important;"')
          }
          this.tagArr = this.product.tagName.split(',')
          if (this.isWeiXin) {
            this.getWxConfig()
            wx.ready(function(){
              console.log('wx config success:');
              wx.onMenuShareAppMessage({
                title: ''+that.product.name,
                desc: ''+that.product.intro,
                link: window.location.href,
                imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
                type: 'link',
                dataUrl: '',
                success: function () {}
              });
              wx.onMenuShareTimeline({
                title: ''+that.product.name,
                link: window.location.href,
                imgUrl: 'https://evhack.oss-cn-hangzhou.aliyuncs.com/cxWeb/ad/logo.png?x-oss-process=style/ori',
                success: function () {}
              });
            });
            wx.error(function(res){
              console.error('wx fail:');
              console.error(res);
            });
          }
        } else {
          Toast.fail({ message: '获取产品详情失败', forbidClick: true });
          console.log(res.data)
        }
      }).catch(err => {
        Toast.fail({ message: '获取产品详情失败', forbidClick: true });
        console.error(err)
      })
    },
    toGetProduct () {
      this.$router.push({ path: '/form', query: { productId: this.product.id, productName: this.product.name } })
    },
    goBack () {
      if (window.history.length <= 1) {
        this.$router.replace({path: '/'})
      } else { this.$router.go(-1) }
    },
    getWxConfig() {
      const url = window.location.href.split('#')[0];
      Toast.loading({ message: '加载中...', forbidClick: true, duration: 1000 });
      this.$get('/mfr/getWxConfig', {url: encodeURIComponent(url)}).then(res => {
        if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
          const result = res.data.data;
          console.log('get wx config:');
          console.log(result);
          if (Object.keys(result).length > 0) {
            wx.config({
              debug: false,
              appId: result.appId,
              timestamp: result.timestamp,
              nonceStr: result.nonceStr,
              signature: result.signature,
              jsApiList: ['updateAppMessageShareData','updateTimelineShareData','onMenuShareTimeline','onMenuShareAppMessage']
            });
          } else {
            Toast.fail({ message: '配置信息获取失败', forbidClick: true });
            console.error('配置信息获取失败');
            console.error(result);
          }
        } else {
          Toast.fail({ message: '配置信息获取失败', forbidClick: true });
          console.error('配置信息获取失败');
          console.error(res.data);
        }
      }).catch(err => {
        Toast.fail({ message: '配置信息获取失败', forbidClick: true });
        console.error('配置信息获取失败');
        console.error(err);
      });
    },
    toShare () {
      if (!this.isWeiXin) { Toast.fail({ message: '请在微信浏览器中打开', forbidClick: true }); return }
      Toast.fail({ message: '请点击右上角分享', forbidClick: true });
    }
  }
}
</script>

<style lang="less">
#swipeBox {
  .van-image {
    display: block;
    font-size: 0;
  }
  .van-image__img {
    width: 100vw;
    height: 100vw;
    display: block;
    font-size: 0;
  }
}
#tagBox {
  .van-image__img {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
}
#nav {
  .van-image__img {
    width: 30px;
    height: 30px;
  }
}
#productContent {
  img {
    display: block;
    font-size: 0;
  }
  .van-image {
    font-size: 0;
  }
  .van-image__img {
    width: 100vw;
    height: 100vw;
  }
}
</style>
<style lang="less" scoped>
.product-detail {
  background-color: #F4F8FB;
  #nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 8px;
    .share {
      float: right;
    }
  }
  #productInfo {
    padding: 8px;
    text-align: left;
    background-color: #FFFFFF;
    .price {
      font-size: 12.75pt;
      color: #F25822;
      span {
        font-weight: bold;
        font-size: 17.25pt;
      }
    }
    h2 {
      margin: 8px 0;
      font-size: 12pt;
      color: #333333;
    }
    p {
      line-height: 1.3;
      margin: 8px 0;
      color: #999999;
      font-size: 10.5pt;
    }
  }
  #tagBox {
    margin: 8px 0;
    padding: 8px;
    text-align: left;
    background-color: #FFFFFF;
    list-style-type: none;
    li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      padding: 4px 10px;
      border-radius: 24px;
      font-size: 7.5pt;
      background-color: #FFDBC9;
      color: #FF5500;
      &:last-child {
        margin: 0;
      }
    }
  }
  #productContent {
    padding-bottom: 50px;
    background-color: #FFFFFF;
    color: #333333;
    font-size: 10.5pt;
    text-align: left;
    .tooltip {
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      line-height: 48px;
      padding: 0 8px;
    }
    img {
      width: 100vw;
      height: auto;
      font-size: 0;
    }
    p {
      padding: 0 8px;
    }
  }
  #goodsAction {
    height: 60px;
  }
}
</style>